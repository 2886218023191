import React from "react";
import Navbar from "/src/containers/navbar";
import { graphql } from "gatsby";
import Home from "/src/components/home";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import { useDispatch } from "react-redux";
import CookiesPopup from "../../containers/cookies/cookies-popup";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import { setCurrentAsset } from "../../redux/actions/misc-actions";
import { resetRegion } from "../../redux/actions/region-actions";
import SetupContainer from "../../setup-container";

const IndexPage = ({ data }) => {
  const dispatch = useDispatch();
  const assets = data.allAsset.nodes.sort((a, b) => parseInt(a.home_order, 10) < parseInt(b.home_order, 10));
  dispatch(setCurrentAsset());
  dispatch(resetRegion());

  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Home page`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <CookiesPopup />
        <Home assets={assets} />
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allAsset {
      nodes {
        id
        asset_id
        home_order
        description
        logo_light_url
        logo_dark_url
        thumb_url
        name
        latitude
        longitude
        city {
          id
          name
        }
      }
    }
  }
`;
