import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import theme from "../../theme-client";
import FeaturesSearchIcon from "../../icons/features-search-icon";
import FeaturesCalendarIcon from "../../icons/features-calendar-icon";
import FeaturesRocketIcon from "../../icons/features-rocket-icon";

const useStyles = makeStyles((th) => ({
  features: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: th.palette.info.main,
    paddingTop: th.spacing(2),
    paddingBottom: th.spacing(5),
    alignItems: "center",
    [th.breakpoints.down("xs")]: {
      height: "100%",
      paddingLeft: th.spacing(2),
      paddingRight: th.spacing(2),
      paddingTop: th.spacing(2),
      paddingBottom: th.spacing(4),
    },
  },
  featuresTitle: {
    textAlign: "center",
    "& > h3": {
      marginBottom: 8,
      paddingTop: 24,
    },
    "& > h4": {
      marginBottom: 4,
    },
    "& > p": {
      fontSize: 16,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  iconCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "64px",
    height: "64px",
  },
  contentCard: {
    paddingTop: th.spacing(2),
    display: "flex",
    flexDirection: "column",
    width: "210px",
    alignItems: "center",
    [th.breakpoints.down("xs")]: {
      marginBottom: th.spacing(2),
    },
  },
  carousel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}));

const FeatureCard = ({ cardIcon, title = "", description = "" }) => {
  const classes = useStyles(theme);
  return (
    <Box className={classes.contentCard}>
      <Box className={classes.iconCard}>{cardIcon}</Box>
      <Box mt={3}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body1" style={{ textAlign: "center" }}>
          <Trans>{description}</Trans>
        </Typography>
      </Box>
    </Box>
  );
};

const FeaturesCards = () => {
  const { i18n } = useLingui();

  return (
    <>
      <FeatureCard
        cardIcon={<FeaturesSearchIcon fill={theme.palette.primary.main} />}
        title={i18n._(t`Search`)}
        description={i18n._(
          t`Browse our platform to discover a variety of available spaces that fit your brand needs from prime mall locations to flexible Kiosks.`,
        )}
      />
      <FeatureCard
        cardIcon={<FeaturesCalendarIcon stroke={theme.palette.primary.main} />}
        title={i18n._(t`Book with Ease`)}
        description={i18n._(t`Choose your desired location, select your desired dates, and secure your booking!`)}
      />
      <FeatureCard
        cardIcon={<FeaturesRocketIcon primary={theme.palette.primary.main} />}
        title={i18n._(t`Launch`)}
        description={i18n._(t`Bring your vision to life! We’ll help you get started quickly and seamlessly.`)}
      />
    </>
  );
};

function Features() {
  const classes = useStyles(theme);

  return (
    <Box className={classes.features}>
      <Box className={classes.content} width="100%">
        <Box className={classes.featuresTitle} mb={[0, 5]} color="text.primary">
          <Typography variant="h3">
            <Trans>Pop-Up, Stand Out.</Trans>
          </Typography>
          <Typography variant="h4">
            <Trans>Search. Book. Launch. It’s that simple.</Trans>
          </Typography>
          <Typography variant="body1">
            <Trans>
              Effortlessly search, seamlessly book, and easily launch your pop-up - all in just a few simple steps.
            </Trans>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="space-evenly"
          alignItems="center"
          bgcolor={theme.palette.info.main}
        >
          <FeaturesCards />
        </Box>
      </Box>
    </Box>
  );
}

export default Features;
