import React from "react";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Image from "material-ui-image";
import Link from "../link";
import assetTheme from "../../theme";
import CardSpot from "./card-spot";
import { getAssetSlug, useQuery } from "../../utils";
import { isDateValid } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  mainTitle: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    color: assetTheme.palette.text.primary,
    textTransform: "uppercase",
    "@media (min-height: 1080px)": {
      fontSize: 28,
    },
  },
  spots: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  descriptionContainer: {
    display: "flex",
    width: "100%",
    minHeight: 250,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
      textAlign: "center",
    },
  },
  imageContainer: {
    position: "relative",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "300px",
    },
  },
  assetContainer: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "100%",
    },
  },
  assetName: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "@media (min-height: 1080px)": {
      fontSize: 28,
    },
  },
  assetDescription: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "@media (min-height: 1080px)": {
      fontSize: 15,
    },
  },
  dossier: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
    "@media (min-height: 1080px)": {
      fontSize: 15,
    },
  },
}));

// this is to refactor. duplicated "home/header"
export const getUrlFromSpotSlug = (spot, asset, start, end) => {
  const customTitle = spot.data?.title || "";
  const spotType = spot.data?.type;

  switch (spotType) {
    case "adspace":
      if (isDateValid(start) && isDateValid(end)) {
        return `/active-adspaces/${asset.id}/?start=${start}&end=${end}&spot=${spot.slug}&spotTitle=${customTitle}`;
      }
      return `/active-adspaces/${asset.id}?spot=${spot.slug}&spotTitle=${customTitle}`;

    default:
      if (isDateValid(start) && isDateValid(end)) {
        return `/active-spaces/${getAssetSlug(asset)}/?start=${start}&end=${end}&spot=${spot.slug}${
          customTitle ? `&spotTitle=${customTitle}` : ""
        }`;
      }
      return `/active-spaces/${getAssetSlug(asset)}/?spot=${spot.slug}${
        customTitle ? `&spotTitle=${customTitle}` : ""
      }`;
  }
};

function AssetSpots({ spots, region, asset }) {
  const classes = useStyles({ ...assetTheme });

  const query = useQuery();
  const start = query.get("start");
  const end = query.get("end");

  return (
    <Box className={classes.container} mt={2} pt={2}>
      <Box display="flex" flexDirection="column">
        <Box ml={[2, 4]}>
          <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
            <Link color="inherit" to="/">
              <Typography variant="h6" style={{ color: assetTheme.palette.gray71 }}>
                Home
              </Typography>
            </Link>
            {region.name && (
              <Link color="inherit" to={`/region-assets/${region.id}/`}>
                <Typography variant="h6" style={{ color: assetTheme.palette.gray71 }}>
                  {region.name}
                </Typography>
              </Link>
            )}
            <Typography variant="h6" style={{ color: assetTheme.palette.black }}>
              {asset?.name}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Typography variant="h2" className={classes.mainTitle}>
          <Trans>Selecciona la opción que más se ajuste a tus necesidades</Trans>
        </Typography>
        <Box className={classes.spots}>
          {spots.map((spot) => (
            <CardSpot
              key={spot.slug}
              spot={spot}
              assetId={asset?.id}
              to={getUrlFromSpotSlug(spot, asset, start, end)}
            />
          ))}
        </Box>
      </Box>
      <Box display="flex" className={classes.descriptionContainer}>
        <Box className={classes.imageContainer}>
          <Image
            src={asset?.thumb_url}
            style={{
              height: "100%",
              padding: "0",
              width: "100%",
              filter: "brightness(70%)",
              position: "absolute",
              flex: 1,
            }}
            imageStyle={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
        </Box>

        <Box className={classes.assetContainer}>
          <Typography variant="h3" className={classes.assetName}>
            {asset?.name}
          </Typography>
          <Typography variant="body1" className={classes.assetDescription}>
            {asset?.description}
          </Typography>

          {asset?.dossier_advertising && (
            <Link target="_blank" href={asset.dossier_advertising}>
              <Typography variant="body1" className={classes.dossier}>
                <Trans>Ver dossier</Trans>
              </Typography>
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AssetSpots;
