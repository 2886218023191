import React, { useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import Image from "material-ui-image";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import TextField from "@material-ui/core/TextField";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import NoSsr from "@material-ui/core/NoSsr";
import { Hidden } from "@material-ui/core";
import { useNavigate } from "../../hooks/navigation";
import config from "../../config";
import { getAssetSlug } from "../../utils";
import SearchIcon from "../../icons/search-icon";
import theme from "../../theme-client";
import { getUrlFromSpotSlug } from "../asset-spots";
import DateSelectModal from "../date-select-modal";
import { formatDateUtc, isDateValid } from "../../utils/dateUtils";
import MapToggle from "./MapToogle";

const useStyles = makeStyles((th) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 550,
  },
  headerTitle: {
    "& > h1": {
      color: "white",
      fontSize: 40,
      textAlign: "center",
    },
    [th.breakpoints.down("sm")]: {
      padding: th.spacing(1),
      "& > h1": {
        fontSize: 32,
      },
    },
    [th.breakpoints.down("xs")]: {
      "& > h1": {
        fontSize: 25,
      },
    },
  },
  headerDescription: {
    "& > h2": {
      color: "white",
      fontSize: 25,
      fontWeight: 400,
      textTransform: "none",
      textAlign: "center",
    },
    [th.breakpoints.down("sm")]: {
      padding: th.spacing(1),
      "& > h2": {
        fontSize: 22,
      },
    },
    [th.breakpoints.down("xs")]: {
      padding: th.spacing(1),
      "& > h2": {
        fontSize: 20,
      },
    },
  },
  headerContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999",
    [th.breakpoints.down("xs")]: {
      gap: 0,
    },
  },
  headerSearchBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    [th.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  searchBox: {
    width: "340px",
    [th.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  customInput: {
    backgroundColor: th.palette.white,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: "6px 4px 5px 4px",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 11px)",
    },
    '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: "0px",
        padding: "6px 4px 5px 4px",
      },
    "& .MuiOutlinedInput-input": {
      color: th.palette.black,
      width: 390,
      [th.breakpoints.down("xs")]: {
        width: "100%",
      },
      transition: th.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
    },
    "& input::placeholder": {
      color: th.palette.black,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  categoryInput: {
    backgroundColor: th.palette.white,
    '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: "0px",
      },
    "& .MuiOutlinedInput-input": {
      color: th.palette.black,
      width: "220px",

      [th.breakpoints.down("xs")]: {
        width: "100%",
      },
      transition: th.transitions.create(["border-color", "box-shadow"]),
      fontSize: 16,
    },
    "& input::placeholder": {
      color: th.palette.black,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  autoCompletePopper: {
    "& .MuiAutocomplete-root": {
      borderRadius: 0,
    },
  },
  searchBoxIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: th.palette.primary.main,
    width: "48px",
    height: "48px",
    cursor: "pointer",
    [th.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  dateContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    [th.breakpoints.down("xs")]: {
      marginTop: th.spacing(1),
      marginLeft: th.spacing(0),
    },
  },
  dateInputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: "5px",
    top: "15px",
    borderRadius: "50%",
    width: "22.85px",
    height: "22.85px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  dateInput: {
    height: "48px",
    width: "215px",
    backgroundColor: th.palette.white,
    "& .MuiOutlinedInput-input": {
      color: th.palette.black,
      fontSize: 16,
      padding: "14.5px 14px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    [th.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const GenericOption = ({ name = "" }) => (
  <Box display="flex" flexDirection="row" alignItems="center" width="100%" height="44px">
    <Box>
      <Typography variant="body1">{name}</Typography>
    </Box>
  </Box>
);

const AssetOption = ({ name = "", image = "" }) => (
  <Box display="flex" flexDirection="row" alignItems="center" width="100%" height="44px">
    <Image
      disableSpinner
      src={image}
      style={{
        width: "48px",
        height: "48px",
        padding: 0,
      }}
      imageStyle={{
        objectFit: "cover",
        width: "48px",
        height: "48px",
        borderRadius: "5px",
      }}
    />
    <Box ml={2}>
      <Typography variant="body1">{name}</Typography>
    </Box>
  </Box>
);

const CustomPopper = (props) => (
  <Popper
    {...props}
    placement="bottom"
    modifiers={{
      flip: {
        enabled: false,
      },
    }}
  />
);

function Header({
  regions,
  assets,
  spots,
  onSelectRegion,
  onSelectAsset,
  flagSeoOptions,
  hasCitiesAndSuburbs,
  searchAssetSpaces,
}) {
  const { navigate } = useNavigate();
  const classes = useStyles({ ...theme });
  const { i18n } = useLingui();
  const [region, setRegion] = useState(null);
  const [asset, setAsset] = useState(null);
  const [spot, setSpot] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openRegionSearchBar, setOpenRegionSearchBar] = useState(false);
  const [openAssetSearchBar, setOpenAssetSearchBar] = useState(false);
  const [openTypeSearchBar, setOpenTypeSearchBar] = useState(false);

  const dateRef = useRef();
  const [dates, setDates] = useState({ start: "", end: "" });
  const [openDateModal, setOpenDateModal] = useState(false);

  const handleGoToAssetPage = () => {
    if (flagSeoOptions) {
      if (region && !asset && !spot) {
        navigate(`/region-assets/${region.id}`);
      } else if (asset && !spot) {
        navigate(`/asset-spots/${getAssetSlug(asset)}`);
      } else if (asset && spot) {
        navigate(getUrlFromSpotSlug(spot, asset, null, null, false));
      } else {
        setOpenError(true);
      }
    } else {
      const params = `?start=${dates?.start}&end=${dates?.end}`;

      navigate(`/asset-spots/${getAssetSlug(asset)}/${params}`);
      searchAssetSpaces(asset, dates.start, dates.end);
    }
  };

  const handleOnInputChange = (itemSelected, inputName) => {
    switch (inputName) {
      case "region":
        onSelectRegion(itemSelected);
        setRegion(itemSelected);
        if (!itemSelected) {
          onSelectAsset(null);
          setAsset(null);
          setSpot(null);
        }
        break;
      case "asset":
        onSelectAsset(itemSelected);
        setAsset(itemSelected);
        if (!itemSelected) {
          setSpot(null);
        }
        break;
      case "spot":
        setSpot(itemSelected);
        break;
      default:
        onSelectRegion(null);
        onSelectAsset(null);
        setRegion(null);
        setAsset(null);
        setSpot(null);
        break;
    }
  };

  const handleChangeDates = (start, end) => {
    if (isDateValid(start) && isDateValid(end)) {
      setDates({
        start: formatDateUtc(start),
        end: formatDateUtc(end),
      });
    } else {
      setDates({
        start: "",
        end: "",
      });
    }
  };

  const handleDisplayDate = () => {
    if (dates.start && dates.end) {
      return `${formatDateUtc(dates.start, "DD/MM/YY")} - ${formatDateUtc(dates.end, "DD/MM/YY")}`;
    }
    return "";
  };

  const mappedSpots = spots.map(({ data, ...rest }) =>
    data?.title ? { ...rest, data, name: data.title } : { ...rest, data },
  );

  return (
    <Box id="header" className={classes.header}>
      <NoSsr>
        <DateSelectModal
          open={openDateModal}
          onClose={() => setOpenDateModal(false)}
          onSelectDates={handleChangeDates}
          startDate={dates.start}
          endDate={dates.end}
          calendarProps={{
            isDouble: true,
            selectRange: true,
          }}
        />
        <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
          <Alert onClose={() => setOpenError(false)} severity="info">
            {flagSeoOptions ? (
              <Trans>Por favor seleccione un centro comercial o una región.</Trans>
            ) : (
              <Trans>Por favor seleccione un centro comercial.</Trans>
            )}
          </Alert>
        </Snackbar>
      </NoSsr>
      <Image
        disableSpinner
        src={config.HOME_HEADER_IMG_URL}
        style={{
          position: "absolute",
          width: "100%",
          height: 550,
          filter: "brightness(40%)",
          padding: 0,
        }}
        imageStyle={{
          objectFit: "cover",
          width: "100%",
          height: 550,
          objectPosition: "left",
        }}
      />
      <Box className={classes.headerContent}>
        <Box className={classes.headerTitle}>
          <Typography variant="h1">
            <Trans>ENCUENTRA EL ESPACIO QUE NECESITAS</Trans>
          </Typography>
        </Box>
        <Box className={classes.headerDescription}>
          <Typography variant="h2">
            <Trans>Encuentra tu espacio perfecto en un centro comercial de tu elección</Trans>
          </Typography>
        </Box>
        <NoSsr>
          <Box display="flex" flexDirection="column" gridGap={32}>
            <Box className={classes.headerSearchBox} mt={2}>
              {flagSeoOptions && (
                <Box
                  className={classes.searchBox}
                  onClick={() => setOpenRegionSearchBar((open) => !open)}
                  onBlur={() => setOpenRegionSearchBar(() => false)}
                >
                  <Autocomplete
                    open={openRegionSearchBar}
                    value={region}
                    loading={regions.length === 0}
                    options={regions}
                    PopperComponent={CustomPopper}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props) => GenericOption({ name: props.name })}
                    onChange={(event, itemSelected) => handleOnInputChange(itemSelected, "region")}
                    popupIcon={<ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          hasCitiesAndSuburbs
                            ? i18n._(t`Seleccione una Ciudad / Suburbio`)
                            : i18n._(t`Seleccione una Región`)
                        }
                        className={classes.customInput}
                      />
                    )}
                  />
                </Box>
              )}
              <Box
                className={classes.searchBox}
                onClick={() => setOpenAssetSearchBar((open) => !open)}
                onBlur={() => setOpenAssetSearchBar(() => false)}
              >
                <Autocomplete
                  open={openAssetSearchBar}
                  value={asset}
                  loading={assets.length === 0}
                  options={assets}
                  PopperComponent={CustomPopper}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props) => AssetOption({ name: props.name, image: props.thumb_url })}
                  onChange={(event, itemSelected) => handleOnInputChange(itemSelected, "asset")}
                  popupIcon={<ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={i18n._(t`Centro Comercial`)}
                      className={classes.customInput}
                    />
                  )}
                />
              </Box>
              {flagSeoOptions && (
                <Box
                  className={classes.searchBox}
                  onClick={() => asset && setOpenTypeSearchBar((open) => !open)}
                  onBlur={() => setOpenTypeSearchBar(() => false)}
                >
                  <Autocomplete
                    open={openTypeSearchBar}
                    value={spot}
                    disabled={!asset}
                    options={mappedSpots}
                    PopperComponent={CustomPopper}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props) => GenericOption({ name: props.name })}
                    onChange={(event, itemSelected) => handleOnInputChange(itemSelected, "spot")}
                    popupIcon={<ArrowForwardIosIcon style={{ transform: "rotate(90deg)" }} />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={i18n._(t`Seleccione tipo de uso`)}
                        style={{
                          backgroundColor: !asset ? theme.palette.lightGray : theme.palette.white,
                        }}
                        className={classes.customInput}
                      />
                    )}
                  />
                </Box>
              )}
              {!flagSeoOptions && (
                <>
                  <Box ref={dateRef} className={classes.dateContainer}>
                    <TextField
                      value={handleDisplayDate()}
                      variant="outlined"
                      onClick={() => setOpenDateModal(true)}
                      placeholder={i18n._(t`Ingreso / Salida`)}
                      className={classes.dateInput}
                    />

                    <Box className={classes.dateInputContainer}>
                      <ArrowForwardIosIcon
                        style={{
                          width: "18.85px",
                          height: "18.85px",
                          color: "rgba(0, 0, 0, 0.54)",
                          transform: "rotate(90deg)",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenDateModal(true)}
                      />
                    </Box>
                  </Box>
                </>
              )}
              <Box className={classes.searchBoxIcon} onClick={handleGoToAssetPage}>
                <SearchIcon fill="white" width="28px" height="24px" />
                <Hidden lgUp>
                  <Box display="flex" alignItems="center" justifyContent="center" ml={2}>
                    <Typography variant="body1" style={{ color: theme.palette.white }}>
                      <Trans>Búsqueda</Trans>
                    </Typography>
                  </Box>
                </Hidden>
              </Box>
            </Box>
            <MapToggle />
          </Box>
        </NoSsr>
      </Box>
    </Box>
  );
}

export default Header;
