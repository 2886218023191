import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { makeStyles, Fade, Button } from "@material-ui/core";
import Supercluster from "supercluster";
import { Trans } from "@lingui/macro";
import config from "../../config";
import PopupDetails from "./popup-details";
import { filterByCity, popupOffsets } from "./utils";
import { getLanguageLocalStorage } from "../../utils";

mapboxgl.accessToken = config.MAPBOX_API_KEY;

const useStylesMapBox = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  mapContainer: {
    height: 500,
    width: "75%",
    "& .mapboxgl-popup-content": {
      padding: "10px 10px 5px",
    },
    "& .mapboxgl-popup-close-button": {
      fontSize: theme.spacing(2),
    },
    "& .mapboxgl-marker": {
      top: "-10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 400,
    },
  },
}));

const MapBox = ({ assets = [] }) => {
  const mapContainer = useRef(null);
  const mainContainer = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ offset: popupOffsets, closeOnClick: false }));
  const mapRef = useRef(null);
  const [initialView, setInitialView] = useState(null);
  const classes = useStylesMapBox();

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: config.HOME_MAP_STYLE,
      bounds: config.HOME_MAP_BOUNDS,
      dragPan: true,
    });

    mapRef.current = map;

    setInitialView({
      center: map.getCenter(),
      zoom: map.getZoom(),
      bounds: map.getBounds(),
    });

    map.setMinZoom(map.getZoom());
    map.scrollZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.touchZoomRotate.disable();
    map.touchPitch.disable();

    map.loadImage(config.MAPBOX_MARKER, (error, image) => {
      if (error) throw error;
      map.addImage("custom-marker", image);

      map.on("load", () => {
        const language = getLanguageLocalStorage();
        map.setLayoutProperty("country-label", "text-field", ["get", `name_${language.languageType}`]);

        const assetsByCity = filterByCity(assets);
        const cluster = new Supercluster({
          radius: 20,
          maxZoom: 12,
        });

        cluster.load(
          Object.keys(assetsByCity).map((city) => ({
            type: "Feature",
            properties: { city },
            geometry: {
              type: "Point",
              coordinates: assetsByCity[city][0].coordinates,
            },
          })),
        );

        map.on("click", "clusters", (e) => {
          const features = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
          const clusterId = features[0].properties.cluster_id;
          map.getSource("assets").getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
        });

        map.addSource("assets", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: Object.keys(assetsByCity).map((city) => ({
              type: "Feature",
              properties: { city },
              geometry: {
                type: "Point",
                coordinates: assetsByCity[city][0].coordinates,
              },
            })),
          },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 20,
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "assets",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": ["step", ["get", "point_count"], "#006a4d", 100, "#006a4d", 750, "#006a4d"],
            "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "assets",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });

        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "assets",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "custom-marker",
            "icon-size": 0.15,
            "icon-allow-overlap": true,
          },
        });

        map.on("mouseenter", "unclustered-point", (e) => {
          const { city } = e.features[0].properties;
          const popupNode = document.createElement("div");
          ReactDOM.render(
            <PopupDetails city={city} assets={assetsByCity[city]} pathname={language.pathname} />,
            popupNode,
          );
          popupRef.current.setLngLat(assetsByCity[city][0].coordinates).setDOMContent(popupNode).addTo(map);
        });

        mainContainer.current.addEventListener("mouseleave", () => {
          popupRef.current.remove();
        });
      });
    });

    return () => map.remove();
  }, [assets]);

  const resetView = () => {
    if (mapRef.current && initialView) {
      mapRef.current.easeTo({
        center: initialView.center,
        zoom: initialView.zoom,
      });
    }
  };

  return (
    <Fade in timeout={1000}>
      <div ref={mainContainer} className={classes.mainContainer}>
        <div ref={mapContainer} className={classes.mapContainer} />
        <Button variant="contained" color="primary" size="large" sx={{ mt: 2 }} onClick={resetView}>
          <Trans>Restaurar vista</Trans>
        </Button>
      </div>
    </Fade>
  );
};

export default MapBox;
