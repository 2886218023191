import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardMedia, Typography, Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import { getAssetSlug } from "../../utils";
import { useNavigate } from "../../hooks/navigation";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    maxWidth: "none",
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
    gap: theme.spacing(2),
    placeItems: "center",
    justifyContent: "center",
    maxWidth: "1500px",
    width: "100%",
    marginTop: theme.spacing(3),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: theme.shadows[3],
    textAlign: "center",
    borderRadius: 15,
    width: "280px",
    minHeight: "280px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: theme.shadows[6],
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(1),
    cursor: "pointer",
  },
  cardImage: {
    objectFit: "cover",
    padding: theme.spacing(0),
    cursor: "pointer",
  },
}));

const FeaturedAssets = () => {
  const classes = useStyles();
  const { navigate } = useNavigate();
  const assets = useSelector((state) => state.misc.assets);

  const filteredAndSortedAssets = assets
    .filter((asset) => asset.home_order > 0)
    .sort((a, b) => a.home_order - b.home_order);

  const handleRedirectToAsset = (asset) => {
    navigate(`/asset-spots/${getAssetSlug(asset)}`);
  };

  if (!filteredAndSortedAssets.length > 0) return null;

  return (
    <Container className={classes.container}>
      <Typography variant="h3" align="center" gutterBottom>
        <Trans>Featured Assets</Trans>
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        <Trans>Discover the Perfect Pop-Up Spot - Explore Our Available Spaces and Start Something Amazing!</Trans>
      </Typography>

      <div className={classes.gridContainer}>
        {filteredAndSortedAssets.map((asset, index) => (
          <Card key={index} className={classes.card} onClick={() => handleRedirectToAsset(asset)}>
            {asset.thumb_url && (
              <CardMedia
                className={classes.cardImage}
                component="img"
                height="180"
                image={asset.thumb_url}
                alt={asset.name}
              />
            )}

            <CardContent className={classes.cardContent}>
              <Typography variant="h5" fontWeight="bold">
                {asset.name}
              </Typography>
              {asset.city?.name && (
                <Typography mt={1} variant="body1">
                  {asset.city.name}
                </Typography>
              )}
              <Typography variant="body2">{asset.address}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default FeaturedAssets;
