import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import { loginSsoThunk } from "../../redux/actions/auth-actions";
import { isMobile } from "../../utils";
import { useNavigate } from "/src/hooks/navigation";
import Header from "../../containers/home/header";
import Assets from "./assets";
import Features from "./features";
import OwnerModal from "../owner-modal";
import { MapVisibilityProvider } from "./useMapVisibility";
import FeaturedAssets from "./featured-assets";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { navigate } = useNavigate();
  const loginStatus = useSelector((state) => state.user.loginStatus);
  const params = new URLSearchParams(location.hash.substring(1));
  const accessToken = params.get("access_token");
  const error = params.get("error");
  const ssoError = error === "invalid_request";

  useEffect(() => {
    if (accessToken) {
      dispatch(loginSsoThunk(accessToken));
    }
  }, []);

  return (
    <Box className={classes.container}>
      {loginStatus === "error" && (
        <OwnerModal
          open={loginStatus === "error"}
          width={340}
          onClose={() => navigate("/signin")}
          title={<Trans>Error al iniciar sesión</Trans>}
          hasDivider={false}
          fullScreen={isMobile()}
        >
          <Box mt={0.5}>
            <Typography variant="body1">
              <Trans>
                Ocurrió un error al intentar obtener los datos del usuario. Por favor, inicia sesión nuevamente.
              </Trans>
            </Typography>
          </Box>
          <Box textAlign="right" mt={3} mb={2}>
            <Button size="large" variant="contained" color="primary" onClick={() => navigate("/signin")}>
              <Trans>INICIAR SESIÓN</Trans>
            </Button>
          </Box>
        </OwnerModal>
      )}

      {ssoError && (
        <OwnerModal
          open={ssoError}
          width={340}
          onClose={() => navigate("/signin")}
          title={<Trans>Error al iniciar sesión</Trans>}
          hasDivider={false}
          fullScreen={isMobile()}
        >
          <Box mt={0.5}>
            <Typography variant="body1">
              <Trans>
                El método de inicio de sesión seleccionado no está disponible para tu cuenta. Por favor, inicia sesión
                utilizando tu nombre de usuario y contraseña.
              </Trans>
            </Typography>
          </Box>
          <Box textAlign="right" mt={3} mb={2}>
            <Button size="large" variant="contained" color="primary" onClick={() => navigate("/signin")}>
              <Trans>INICIAR SESIÓN</Trans>
            </Button>
          </Box>
        </OwnerModal>
      )}
      <MapVisibilityProvider>
        <Header />
        <Assets />
      </MapVisibilityProvider>
      <FeaturedAssets />
      <Features />
    </Box>
  );
}

export default Home;
