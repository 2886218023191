import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import theme from "../../theme-client";
import MapBox from "../map-box";
import { useMapVisibility } from "./useMapVisibility";

const useStyles = makeStyles((th) => ({
  assets: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
    backgroundColor: th.palette.info.main,
    paddingTop: th.spacing(5),
    paddingBottom: th.spacing(5),
    [th.breakpoints.down("xs")]: {
      paddingTop: th.spacing(4),
      paddingBottom: th.spacing(4),
    },
  },
  titles: {
    marginBottom: th.spacing(1),
    textAlign: "center",
    [th.breakpoints.down("xs")]: {
      minHeight: "auto",
      paddingLeft: th.spacing(2),
      paddingRight: th.spacing(2),
    },
  },
}));

function Assets() {
  const classes = useStyles({ ...theme });
  const assets = useSelector((state) => state.misc.assets);
  const { isMapVisible } = useMapVisibility();

  return (
    <>
      <Box className={classes.assets}>
        <Box className={classes.titles}>
          <Box color="text.primary" mb={1}>
            <Typography variant="h3">
              <Trans>ALQUILA ESPACIOS A CORTO PLAZO EN NUESTROS CENTROS</Trans>
            </Typography>
          </Box>
          <Typography variant="body1" style={{ textTransform: "initial", fontSize: [12, 18] }}>
            <Trans>Te ofrecemos el espacio perfecto para tus ideas. Escoge el centro comercial que prefieras.</Trans>
          </Typography>
        </Box>
        {isMapVisible && <MapBox assets={assets} />}
      </Box>
    </>
  );
}

export default Assets;
