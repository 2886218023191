import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Trans } from "@lingui/macro";
import Typography from "@material-ui/core/Typography";
import { getThemeById } from "../../theme";
import { getIconFromSpotSlug } from "../../utils/spots";
import Link from "../link";
import theme from "../../theme";

const useStyles = makeStyles({
  root: {
    width: 385,
    height: 150,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "@media (min-height: 601px)": {
      width: 320,
      height: 320,
    },
    "@media (min-height: 1081px)": {
      width: 380,
      height: 380,
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
  icon: {
    height: 55,
    width: 55,
  },
  content: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    gap: "16px",
    "@media (max-height: 600px)": {
      flexDirection: "row",
      "&.MuiCardContent-root .MuiBox-root": {
        "&:nth-child(odd)": {
          width: "50%",
        },
        "&:nth-child(even)": {
          alignItems: "start",
          textAlign: "left",
        },
      },
    },
  },
});

export default function SpotCard({ assetId, spot, to }) {
  const theme = getThemeById(assetId);
  const classes = useStyles(theme);

  const handleSpotCardSelected = (slug) => {
    localStorage.setItem("AssetSpotCardSelected", slug);
  };

  return (
    <Link to={to} style={{ marginBottom: 10 }}>
      <Card className={classes.root} elevation={4} onClick={() => handleSpotCardSelected(spot.slug)}>
        <CardContent className={classes.content}>
          <Box mt={2} display="flex" alignItems="center" justifyContent="center" width="100%">
            {getIconFromSpotSlug(spot.slug)({
              className: classes.icon,
              fill: theme.palette.primary.main,
            })}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
            <Typography gutterBottom variant="h2">
              {spot.data?.title ? (
                spot.data?.title
              ) : (
                <>
                  {spot.slug === "advertising" && <Trans>Advertising</Trans>}
                  {spot.slug === "promotion" && <Trans>Promotion</Trans>}
                  {spot.slug === "retail" && <Trans>Retail & Others</Trans>}
                </>
              )}
            </Typography>
            <Typography variant="body1">
              {spot.data?.description ? (
                spot.data?.description
              ) : (
                <>
                  {spot.slug === "advertising" && (
                    <Trans>Publicita tu marca a través de nuestros espacios estratégicos</Trans>
                  )}
                  {spot.slug === "promotion" && (
                    <Trans>Promociona tu marca a través de nuestros espacios exclusivos</Trans>
                  )}
                  {spot.slug === "retail" && <Trans>Encuentra el mejor espacio para comercializar tu marca</Trans>}
                </>
              )}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
}
