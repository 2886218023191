import React, { createContext, useContext, useMemo, useState, useEffect } from "react";

const MapVisibilityContext = createContext();

export function MapVisibilityProvider({ children }) {
  const [isMapVisible, setIsMapVisible] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem("showMap");
    if (savedState !== null) {
      setIsMapVisible(savedState === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("showMap", isMapVisible);
  }, [isMapVisible]);

  const toggleMap = () => setIsMapVisible((prev) => !prev);

  const value = useMemo(() => ({ isMapVisible, toggleMap }), [isMapVisible]);

  return <MapVisibilityContext.Provider value={value}>{children}</MapVisibilityContext.Provider>;
}

export function useMapVisibility() {
  const context = useContext(MapVisibilityContext);
  if (!context) {
    throw new Error("useMapVisibility must be used within a MapVisibilityProvider");
  }
  return context;
}
