import React from "react";
import { Box, Switch, FormControlLabel, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import theme from "../../theme-client";
import { useMapVisibility } from "./useMapVisibility";

const useStyles = makeStyles((th) => ({
  toogle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    borderRadius: "30px",
    backgroundColor: th.palette.primary.main,
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background 0.3s ease",
    width: "fit-content",
    alignSelf: "center",
  },
  thumb: {
    paddingTop: th.spacing(1),
  },
}));

const MapToggle = () => {
  const classes = useStyles({ ...theme });

  const { isMapVisible, toggleMap } = useMapVisibility();

  return (
    <Box className={classes.toogle}>
      <FormControlLabel
        control={
          <Switch
            classes={{ switchBase: classes.thumb }}
            checked={isMapVisible}
            onChange={() => toggleMap()}
            color="secondary"
          />
        }
        label={isMapVisible ? <Trans>Ocultar Mapa</Trans> : <Trans>Mostrar Mapa</Trans>}
      />
    </Box>
  );
};

export default MapToggle;
