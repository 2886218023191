import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRegionsThunk, getAssetsThunk, getSpotsByAssetThunk } from "../../redux/actions/misc-actions";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";
import { dateToDayjs } from "../../utils/dateUtils";
import HeaderComponent from "../../components/home/header";

function Header() {
  const dispatch = useDispatch();
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const regions = useSelector((state) => state.misc.regions || []);
  const assetsSPA = useSelector((state) => state.misc.assets || []);
  const spots = useSelector((state) => state.misc.spots[selectedAsset?.id] || []);
  const flagSeoOptions = useSelector((state) => state.misc.featureFlags["flag.rel.seo_options"]);
  const hasCitiesAndSuburbs = useSelector((state) => state.misc.featureFlags["flag.exp.show_cygnet_west_partnership"]);

  const sortAssetsByName = (assetsList) => {
    return assetsList.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  useEffect(() => {
    dispatch(getRegionsThunk());
    dispatch(
      getAssetsThunk({
        numberPerPage: 200,
      }),
    );
  }, [dispatch]);

  const handleSelectRegion = (region) => {
    if (region && region.id) {
      dispatch(
        getAssetsThunk({
          numberPerPage: 200,
          regions: region.id,
        }),
      );
    } else {
      dispatch(
        getAssetsThunk({
          numberPerPage: 200,
        }),
      );
    }
  };

  const handleSelectAsset = (asset) => {
    setSelectedAsset(asset);
    if (asset && asset.id) {
      dispatch(getSpotsByAssetThunk(asset.id));
    }
  };

  useEffect(() => {
    if (assetsSPA.length) {
      setAssets(sortAssetsByName(assetsSPA));
    }
  }, [assetsSPA]);

  const searchAssetSpaces = (assetValue, start, end) => {
    dispatch(
      addSpacesFilterThunk({
        start: dateToDayjs(start).toDate(),
        end: dateToDayjs(end).toDate(),
        asset: assetValue,
      }),
    );
  };

  return (
    <HeaderComponent
      regions={regions}
      assets={assets}
      spots={spots}
      onSelectRegion={handleSelectRegion}
      onSelectAsset={handleSelectAsset}
      flagSeoOptions={flagSeoOptions}
      hasCitiesAndSuburbs={hasCitiesAndSuburbs}
      searchAssetSpaces={searchAssetSpaces}
    />
  );
}

export default Header;
